import { createApp } from "vue";
import App from "./App.vue";
import { Frontegg } from "@frontegg/vue";
import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory("/"),
  routes: [
    { name: "HomePage", path: "/", component: App },
  ],
});

const app = createApp(App).use(router);

app.use(Frontegg, {
  contextOptions: {
    baseUrl: "https://cridge.frontegg.com",
    clientId: 'ce263d10-a06e-4ed7-a4ef-a3917ca58f40',
    redirectUri: 'https://cridge.pro'
  },
   authOptions: {
    // keepSessionAlive: true // Uncomment this in order to maintain the session alive
  },
  hostedLoginBox: true,
  router,
});

app.mount("#app");